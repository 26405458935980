import React, { useEffect, useState } from "react";

import { db } from "../../../middleware/firebase";
import Header from "../components/header";
import { Toast } from "../components/alerts";
import { ListItem } from "../../common/lists";

import PoppuSection from "./popup";
import { useParams } from "react-router-dom";

const PopUps = () => {
  const [popups, setpopups] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedPopup, setSelectedPopup] = useState(false);
  const { past } = useParams();

  useEffect(() => {
    fetchZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPopups();
    setSelectedPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [past]);

  const fetchZones = async () => {
    const zonesRef = await db.collection("warehouse").get();
    const set_zones = [];
    if (zonesRef.empty) {
      console.log("No matching zones.");
      return;
    }
    zonesRef.forEach((doc) => {
      const zone_data = doc.data();
      set_zones.push(zone_data);
    });
    set_zones.push({
      id: "0",
      descrp: "Nacional",
      idzalm: "Nacional",
      xiams: [],
    });
    setZones(set_zones);
  };

  const fetchPopups = async () => {
    const popupsRef = db.collection("popups");
    const set_popups = [];
    const operator = past ? "<" : ">";
    const start = new Date();
    const snapshot = await popupsRef.where("valid", operator, start).get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      setpopups([]);
      return;
    }

    snapshot.forEach((doc) => {
      const popup_data = doc.data();
      popup_data.id = doc.id;
      set_popups.push(popup_data);
    });
    setpopups(set_popups.sort((a, b) => (a.order > b.order ? 1 : -1)));
  };

  const handleSelect = (ev) => {
    const popup_id = ev.currentTarget.id;
    if (popup_id === "new") {
      const new_popup = {
        id: "new",
      };
      setSelectedPopup(new_popup);
    } else {
      const selected = popups.find((i) => i.id === popup_id);
      const selected_copy = { ...selected };
      setSelectedPopup(selected_copy);
    }
  };

  const disSelect = () => {
    setSelectedPopup(false);
  };

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar has-sidebar-fluid has-sidebar-expand-xl">
          <div className="page-inner  position-relative px-2 px-md-3">
            <Header title="Pop-ups Home" />

            <div className="board p-0 ">
              <div
                className="list-group list-group-flush list-group-divider border-top hovered"
                data-toggle="radiolist"
              >
                {popups &&
                  popups.map((item) => {
                    const date = item.timestamp.toDate();
                    return (
                      <ListItem
                        title={item.title}
                        handleSelect={handleSelect}
                        key={`popup_${item.id}`}
                        id={item.id}
                        help={
                          "Creado:" +
                          date.toLocaleDateString("es-gb", {
                            month: "long",
                            day: "numeric",
                          })
                        }
                        letter={item.order}
                      />
                    );
                  })}
              </div>
              <button
                type="button"
                className="btn btn-primary btn-floated position-fixed"
                title="Add new popup"
                onClick={handleSelect}
                id="new"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>

          {selectedPopup && (
            <PoppuSection
              popup_data={selectedPopup}
              zones={zones}
              disSelect={disSelect}
              fetchPopups={fetchPopups}
            />
          )}
        </div>
      </div>
      <Toast />
    </main>
  );
};

export default PopUps;
