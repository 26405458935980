import React, { useEffect, useState } from "react";
import moment from "moment";

import axios from "../../../middleware/axios";
import Header from "../components/header";
import Logs from "./logs";
import Aggregates from "./aggregates";
import { RichButton } from "../components/buttons";
import { ModalDefault } from "../components/modals";
import { TextArea, InputGroup } from "../components/forms";
import { Toast } from "../components/alerts";

const ProductsPage = () => {
  const [dbUpdateBtn, setUpdateBtn] = useState(true);
  const [modal, setModal] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [toast, setToast] = useState({});

  useEffect(() => {
    const time = moment().format("H");
    ///allow db update only ofter 7 pm
    if (time >= 19) {
      setUpdateBtn(true);
    }
  }, []);
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5000);
    }
  }, [toast]);

  const toggleModal = async () => {
    if (modal === true) {
      setModal(!modal);
    }
    setModal(false);
  };

  const productsModal = async () => {
    setModal("product_update");
  };
  const brandsModal = async () => {
    setModal("brand_update");
  };

  const updateAllProducts = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Actualizando...";
    try {
      const response = await axios.post("/scriptsProductsInitialupdate");
      console.log("index.js:42 | response", response);
      button.classList.remove("disabled");
      button.textContent = "Actualizar";
      toggleModal();
      setToast({ txt: "Actualizando productos" });
    } catch (error) {
      console.log("error", error);
      button.classList.remove("disabled");
      button.textContent = "Actualizar";
    }
  };

  const updateImages = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Actualizando...";
    try {
      const response = await axios.get("/scriptsProductsUpdateImages");
      console.log("index.js:42 | response", response);
      button.classList.remove("disabled");
      button.textContent = "Actualizar";
      toggleModal();
      setToast({ txt: "Imágenes actualizadas" });
    } catch (error) {
      console.log("error", error);
      button.classList.remove("disabled");
      button.textContent = "Actualizar";
    }
  };

  const downloadProductList = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Descargando...";
    try {
      const response = await axios.get(
        "/scriptsReportsProductsDb",
        { responseType: "blob" } // Set the response type to blob
      );
      console.log("index.js:88 | response", response);

      const date = moment().format("DD MMMM YYYY HH:mm");
      const filename = `Reporte Productos Website Mathasa ${date}`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();

      button.classList.remove("disabled");
      button.textContent = "Descargar";
    } catch (error) {
      console.log("error", error);
      button.classList.remove("disabled");
      button.textContent = "Descargar";
    }
  };

  const updateByBrand = async (ev) => {
    const button = ev.target;
    if (!inputValue.product_brand) return;
    button.classList.add("disabled");
    button.textContent = "Actualizando...";
    const brand_name = inputValue.product_brand.trim().toLocaleUpperCase();
    try {
      const { data } = await axios.post("/scriptsProductsUpdateProductsV2", {
        brands: [brand_name],
      });
      button.classList.remove("disabled");
      button.textContent = "Inciar Actualización de Productos";
      if (data.error) {
        setToast({
          txt: data.error,
          type: "danger",
        });
        return;
      }
      setToast({
        txt: `Actualización de productos "${inputValue.product_brand}" iniciada`,
      });
      toggleModal();
    } catch (error) {
      console.log("error", error);
      button.classList.remove("disabled");
      button.textContent = "Inciar Actualización de Productos";
      setToast({
        txt: `Hubo un error, favor de intentar mas tarde`,
        type: "danger",
      });
    }
  };

  const updateByIds = async (ev) => {
    const button = ev.target;
    if (!inputValue.product_id_part) return;
    button.classList.add("disabled");
    button.textContent = "Actualizando...";
    try {
      const { data } = await axios.post("/scriptsProductsUpdateProductsV2", {
        idparts: inputValue.product_id_part.split(","),
      });
      button.classList.remove("disabled");
      button.textContent = "Inciar Actualización de Productos";
      if (data.error) {
        setToast({
          txt: data.error,
          type: "danger",
        });
        return;
      }
      setToast({
        txt: `Productos actualizados`,
      });
      toggleModal();
    } catch (error) {
      console.log("error", error);
      button.classList.remove("disabled");
      button.textContent = "Inciar Actualización de Productos";
      setToast({
        txt: `Hubo un error, favor de intentar mas tarde`,
        type: "danger",
      });
    }
  };

  const handleType = (ev) => {
    const value = ev.target.value;
    const type = ev.target.id;
    const current_type = { ...inputValue };
    current_type[type] = value;
    setInputValue(current_type);
  };

  const clearType = (ev) => {
    const type = ev.currentTarget.dataset.id;
    const current_type = { ...inputValue };
    current_type[type] = "";
    setInputValue(current_type);
  };

  const options = [
    {
      title: "Actualizar Base de Datos",
      color: "blue",
      txt: "Borra y sustituye la base de datos de productos por completo. Solo se puede correr después de las 7 pm",
      click: {
        fun: dbUpdateBtn ? updateAllProducts : false,
        class: dbUpdateBtn ? "btn-danger" : "btn-secondary disabled",
        txt: dbUpdateBtn ? "Ejecutar" : "Después de las 7 pm",
      },
      icon: "fas fa-broom",
    },
    {
      title: "Actualizar Imágenes",
      color: "blue",
      txt: "Actuliza las imagenes pendientes de los productos",
      click: {
        fun: updateImages,
        txt: "Actualizar",
      },
      icon: "far fa-images",
    },
    {
      title: "Actualizar Productos",
      color: "blue",
      txt: "Actualiza la información de productos con el Número de parte",
      click: {
        fun: productsModal,
        txt: "Actualizar Productos",
      },
      icon: "fas fa-sync",
    },
    {
      title: "Actualizar Productos por Marca",
      color: "blue",
      txt: "Actualiza la información de productos por proveedor",
      click: {
        fun: brandsModal,
        txt: "Actualizar Productos por Marca",
      },
      icon: "fas fa-layer-group",
    },
    {
      title: "Iconos de productos",
      color: "secondary",
      txt: "Administra los iconos a mostrar en productos",
      to: "/products/icons",
      showButton: "Administrar",
      icon: "far fa-file-image",
    },
    {
      title: "Descargar lista de productos",
      color: "blue",
      txt: "Descarga archivo CSV con la lista de productos",
      click: {
        fun: downloadProductList,
        txt: "Descargar",
      },
      icon: "fas fa-file-csv",
    },
  ];

  return (
    <main className="app-main">
      <div className="wrapper">
        <div className="page has-sidebar ">
          <div className="page-inner px-2 px-md-3">
            <Header title="Productos" />
            <div className="cards-home">
              {options.map((item, key) => (
                <RichButton
                  classes="card-width"
                  key={`productAction${key}`}
                  icon={item.icon}
                  bgColor={item.color}
                  title={item.title}
                  desc={item.txt}
                  clickFun={item.click}
                  to={item.to}
                  showButton={item.showButton || false}
                />
              ))}
            </div>
          </div>
          <div className="container">
            <Aggregates />
            <Logs />
          </div>
        </div>
      </div>
      <ModalDefault
        show={modal === "product_update" ? true : false}
        title="Buscar Producto"
        action_txt="Inciar Actualización de Productos"
        actionFun={updateByIds}
        toggle={toggleModal}
      >
        <TextArea
          value={inputValue.product_id_part}
          help="Ingresa los números de parte separados por ','"
          placeholder="Ej: 542154, 545421 "
          id="product_id_part"
          handleType={handleType}
        />
      </ModalDefault>
      <ModalDefault
        show={modal === "brand_update" ? true : false}
        title="Actualizar por Marca"
        action_txt="Inciar Actualización de Productos"
        actionFun={updateByBrand}
        toggle={toggleModal}
      >
        <InputGroup
          value={inputValue.product_brand}
          help="Ingresa una marca a la vez"
          placeholder="Frikko"
          id="product_brand"
          handleType={handleType}
          clear={clearType}
        />
      </ModalDefault>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
        toggle={() => setToast(false)}
      />
    </main>
  );
};

export default ProductsPage;
