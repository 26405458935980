import React, { useEffect, useState } from "react";
import moment from "moment";

import axios from "../../../middleware/axios";
import { Toast } from "../components/alerts";
import { db } from "../../../middleware/firebase";
import { joinByComma } from "../../../middleware/common-functions";
import { FormGroup, TextArea, Select } from "../components/forms";
import { ModalDefault } from "../components/modals";
import AutocompleteInput from "../components/autocomplete_input";

const productTypes = [
  {
    text: "Por Números de Parte",
    value: "products",
  },
  {
    text: "Por Marca",
    value: "brand",
  },
  {
    text: "Por Categorías",
    value: "cats",
  },
];

const IconData = ({ icon_data, disSelect, fetchIcons }) => {
  const [icon, setIcon] = useState({ ...icon_data });
  const [modal, setModal] = useState(false);
  const [toast, setToast] = useState({});

  useEffect(() => {
    if (icon_data.valid) {
      icon_data.valid = moment(icon_data.valid.toDate()).format("YYYY-MM-DD");
    } else {
      icon_data.valid = moment().add(6, "M").format("YYYY-MM-DD");
    }
    if (icon_data.from) {
      icon_data.from = moment(icon_data.from.toDate()).format("YYYY-MM-DD");
    } else {
      icon_data.from = moment().format("YYYY-MM-DD");
    }
    setIcon(icon_data);
  }, [icon_data]);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5000);
    }
  }, [toast]);

  const handleType = (ev) => {
    const new_icon = { ...icon };
    const type = ev.target.id;
    const value = ev.target.value;

    new_icon[type] = value;

    setIcon(new_icon);
  };

  const saveIcon = async (ev) => {
    const icon_data = { ...icon };
    if (!icon_data.name) {
      return;
    }
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    if (icon_data.idparts && typeof icon_data.idparts === "string") {
      icon_data.idparts = icon_data.idparts.split(",").map((x) => x.trim());
    }
    if (
      icon_data.type === "percentage_all" ||
      icon_data.type === "percentage"
    ) {
      icon_data.value = icon_data.value / 100;
    }
    try {
      const { data } = await axios.post("scriptsProductsSaveIconImage", {
        icon: icon_data,
      });
      button.classList.remove("disabled");
      button.textContent = "Guardar";
      if (data.error) {
        setToast({
          txt: data.error,
          type: "danger",
        });
        return;
      }
      if (icon.id !== "new") {
      } else {
        setIcon(data);
      }
      fetchIcons();
    } catch (error) {
      console.log("icon.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };

  const renderProductsAdd = () => {
    switch (icon.addType) {
      case "brand":
        return (
          <AutocompleteInput
            key="marcas"
            multiple
            handleType={handleType}
            placeholder="Seleccionar marca"
            value={icon.brands}
            type="brands"
            id="brands"
            label="Marca"
          />
        );
      case "cats":
        return (
          <AutocompleteInput
            key="categorias"
            multiple
            handleType={handleType}
            value={icon.cats}
            placeholder="Seleccionar categoria"
            id="cats"
            type="categories"
            label="Categorías"
          />
        );
      default:
        return (
          <TextArea
            label="Productos Aplicados"
            help="Separar números de parte por coma. Ej: 542154, 545421"
            placeholder="Pega aquí los números de parte separados por comas"
            value={icon.idparts ? joinByComma(icon.idparts) : ""}
            handleType={handleType}
            id="idparts"
          />
        );
    }
  };

  const removeIcon = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    const collection = db.collection("icons").doc(icon.id);
    button.textContent = "Eliminando...";

    try {
      await collection.delete();
      fetchIcons();
      disSelect();
    } catch (error) {
      console.log("icon.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };
  console.log(icon);
  return (
    <div className="page-sidebar bg-light p-3">
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={icon.name || ""}
            id="name"
            label="Nombre del icono"
          />
          <div className="row">
            <div className="col-md-6">
              {" "}
              <FormGroup
                handleType={handleType}
                value={icon.img_tl || ""}
                id="img_tl"
                icon="fas fa-external-link-square-alt fa-flip-horizontal"
                label="URL Imagen Superior Izquierda"
              />
            </div>
            <div className="col-md-6">
              <FormGroup
                handleType={handleType}
                value={icon.img_tr || ""}
                id="img_tr"
                icon="fas fa-external-link-square-alt "
                label="URL Imagen Superior Derecha"
              />
            </div>
            <div className="col-md-6">
              <FormGroup
                handleType={handleType}
                value={icon.img_bl || ""}
                id="img_bl"
                icon="fas fa-external-link-square-alt fa-flip-both"
                label="URL Imagen Inferior Izquierda"
              />
            </div>
            <div className="col-md-6">
              <FormGroup
                handleType={handleType}
                value={icon.img || ""}
                id="img"
                icon="fas fa-external-link-square-alt fa-rotate-90"
                label="URL Imagen Inferior Derecha"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormGroup
                handleType={handleType}
                value={icon.from}
                id="from"
                type="date"
                label="Mostar desde"
              />
            </div>
            <div className="col">
              <FormGroup
                handleType={handleType}
                value={icon.valid}
                id="valid"
                type="date"
                label="hasta"
              />
            </div>
          </div>
          <Select
            value={icon.addType || "products"}
            id="addType"
            label="Aplicar por:"
            options={productTypes}
            handleChange={handleType}
          />
          {renderProductsAdd()}
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button className="btn btn-block btn-success" onClick={saveIcon}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {icon.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeIcon}
        title={icon.code ? `¿Borrar Icono #${icon.name}?` : ""}
        action_btn="danger"
        action_txt="Borrar Icono"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
        toggle={() => setToast(false)}
      />
    </div>
  );
};

export default IconData;
