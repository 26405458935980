import React, { useEffect, useState } from "react";
import moment from "moment";

import { Header } from "../components/side_components";
import firebase, { db } from "../../../middleware/firebase";
import { FormGroup, CheckboxGroup, TextArea } from "../components/forms";
import { ModalDefault } from "../components/modals";

const websites = [
  "decasahermosillo.com",
  "decasajuarez.com",
  "decasa.mx",
  "idecasa.mx",
  "mathasa.mx",
  "designmathasa.mx",
];

const PopupData = ({ popup_data, disSelect, fetchPopups, zones }) => {
  const [popup, setPopup] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (popup_data.valid) {
      popup_data.valid = moment(popup_data.valid.toDate()).format("YYYY-MM-DD");
    } else {
      popup_data.valid = moment().add(1, "M").format("YYYY-MM-DD");
    }
    if (popup_data.from) {
      popup_data.from = moment(popup_data.from.toDate()).format("YYYY-MM-DD");
    } else if (popup_data.id === "new") {
      popup_data.from = moment().format("YYYY-MM-DD");
    }
    if (!popup_data.websites) popup_data.websites = [];
    popup_data.handle_websites = websites.map((item) => {
      const finded = popup_data.websites.find((web) => web === item);
      return { value: item, checked: Boolean(finded) };
    });
    popup_data.handle_zones = zones.map((item) => {
      let finded = false;
      if (popup_data.zones) {
        finded = popup_data.zones.find((web) => web === item.id);
      }
      return { id: item.id, value: item.descrp, checked: Boolean(finded) };
    });
    setPopup(popup_data);
  }, [popup_data, zones]);

  const handleType = (ev) => {
    const new_popup = { ...popup };
    const type = ev.target.id;
    let value = ev.target.value;
    value = value && !isNaN(value) ? parseInt(value) : value;
    new_popup[type] = value;
    setPopup(new_popup);
  };

  const handleCheckbox = (ev) => {
    const new_popup = { ...popup };
    let value = ev.target.value;
    let type = ev.target.dataset.type; //// websites, zones
    if (!new_popup[type]) {
      new_popup[type] = [];
    }
    if (ev.target.checked) {
      new_popup[type].push(value);
    } else {
      const index = new_popup[type].indexOf(value);
      if (index !== -1) {
        new_popup[type].splice(index, 1);
      }
    }
    setPopup(new_popup);
  };

  const savePopup = async (ev) => {
    const collection = db.collection("popups");
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";

    try {
      const current_popup = { ...popup };

      delete current_popup.handle_websites;
      delete current_popup.handle_zones;
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      current_popup.timestamp = timestamp;
      current_popup.valid = new Date(current_popup.valid);
      current_popup.from = new Date(current_popup.from);
      if (popup.id !== "new") {
        await collection.doc(popup.id).set({ ...current_popup });
      } else {
        const new_popup = await collection.add({ ...current_popup });
        current_popup.id = new_popup.id;
      }

      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchPopups();
    } catch (error) {
      console.log("popup.js:87 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const toggleModal = async (ev) => {
    setModal(!modal);
  };
  const removePopup = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    const collection = db.collection("popups").doc(popup.id);
    button.textContent = "Eliminando...";

    try {
      await collection.delete();
      fetchPopups();
      disSelect();
    } catch (error) {
      console.log("popup.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header title={popup.title || "Nuevo Popup"} />
      {popup.id !== "new" && popup.img ? (
        <img src={popup.img} className="img-fluid mb-2" alt="popupimg" />
      ) : null}
      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={popup.title || ""}
            id="title"
            label="Título del Popup"
          />
          <TextArea
            handleType={handleType}
            value={popup.description || ""}
            id="description"
            help={`Máximo 200 caracteres. Caracteres restantes: ${
              popup.description ? 200 - popup.description.length : 200
            }`}
            label="Texto del Popup"
          />
          <FormGroup
            handleType={handleType}
            value={popup.img || ""}
            id="img"
            icon="fas fa-window-maximize"
            label="URL Imagen Popup"
          />
          <FormGroup
            handleType={handleType}
            value={popup.mobile_img || ""}
            id="mobile_img"
            icon="far fa-window-maximize"
            label="URL Imagen Popup Móvil"
          />
          <FormGroup
            handleType={handleType}
            value={popup.link || ""}
            id="link"
            label="Link de Popup"
            placeholder="Ej: /info/sucursales "
            help="Para links dentro de las páginas de Mathasa.mx, cargar unicamente depués de la primer `/` en la url"
          />
          <div className="row">
            <div className="col">
              <FormGroup
                handleType={handleType}
                value={
                  popup.link_txt !== undefined ? popup.link_txt : "Ver más"
                }
                id="link_txt"
                label="Texto en botón"
                placeholder="Ej: Conoce más"
              />
            </div>
            <div className="col">
              <div className={`form-group`}>
                <label className="opacity_0">Muestra</label> <br />
                <button
                  className="btn btn-danger"
                  onClick={() => (window.location.href = popup.link)}
                >
                  {popup.link_txt || "Ver más"}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormGroup
                handleType={handleType}
                value={popup.from || ""}
                id="from"
                type="date"
                label="Mostrar desde"
              />
            </div>
            <div className="col">
              <FormGroup
                handleType={handleType}
                value={popup.valid || ""}
                id="valid"
                type="date"
                label="Hasta"
              />
            </div>
          </div>
          <CheckboxGroup
            options={popup_data.handle_websites}
            title="Visible en páginas:"
            type="websites"
            hadnleCheck={handleCheckbox}
          />
          <CheckboxGroup
            options={popup_data.handle_zones}
            title="Visible en zonas:"
            type="zones"
            hadnleCheck={handleCheckbox}
          />
          <div className="row justify-content-between">
            <div className="col-md-3">
              {" "}
              <button
                className="btn btn-block btn-outline-dark "
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              {" "}
              <button className="btn btn-block btn-success" onClick={savePopup}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {popup.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removePopup}
        title={popup.title ? `¿Borrar Popup ${popup.title}?` : ""}
        action_btn="danger"
        action_txt="Borrar Popup"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
    </div>
  );
};

export default PopupData;
